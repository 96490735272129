@use "@angular/material" as mat;
@use "./_variables" as naybrs;
@use "sass:map";

@include mat.elevation-classes();
@include mat.app-background();

$my-primary: mat.m2-define-palette(naybrs.$naybrs-palette, 500);
$my-accent: mat.m2-define-palette(naybrs.$naybrs-palette, A200, A100, A400);
$my-dark: mat.m2-define-palette(naybrs.$naybrs-palette, A200, A100, A400);

$body: mat.m2-define-typography-level(
  $font-family:
    "-apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji",
  $font-weight: 500,
  $font-size: 1rem,
  $line-height: 1.2,
  $letter-spacing: normal,
);

$headline-1: mat.m2-define-typography-level(
  $font-size: 60px,
  $font-weight: 700,
);

$headline-2: mat.m2-define-typography-level(
  $font-size: 36px,
  $font-weight: 700,
  $line-height: 1,
  $letter-spacing: 0.02em,
);

$headline-3: mat.m2-define-typography-level(
  $font-size: 28px,
  $font-weight: 500,
);

$headline-4: mat.m2-define-typography-level(
  $font-size: 18px,
  $font-weight: 500,
  $line-height: 1,
);

$headline-5: mat.m2-define-typography-level(
  $font-size: 16px,
  $font-weight: 400,
  $line-height: 24px,
  $letter-spacing: 0.05em,
);

$headline-6: mat.m2-define-typography-level(
  $font-size: 18px,
  $font-weight: 400,
  $line-height: 24px,
);

$my-typography: mat.m2-define-typography-config(
  $headline-1: $headline-1,
  $headline-2: $headline-2,

  $headline-3: $headline-3,
  $headline-4: $headline-4,
  $headline-5: $headline-5,
  $headline-6: $headline-6,
  $subtitle-1: $body,
  $subtitle-2: $body,
  $body-1: $body,
  $body-2: $body,
  $button: $body,
  $caption: $body,
  $overline: $body,
);

$my-theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $my-primary,
      accent: $my-accent,
    ),
    typography: $my-typography,
  )
);

h1 {
  font-size: map.get(map.get($my-typography, "headline-1"), "font-size") !important;
  font-weight: map.get(map.get($my-typography, "headline-1"), "font-weight") !important;
  line-height: 60px !important;
}

h2 {
  font-size: map.get(map.get($my-typography, "headline-2"), "font-size") !important;
  font-weight: map.get(map.get($my-typography, "headline-2"), "font-weight") !important;
}

h3 {
  font-size: map.get(map.get($my-typography, "headline-3"), "font-size") !important;
  font-weight: map.get(map.get($my-typography, "headline-3"), "font-weight") !important;
}

h4 {
  font-size: map.get(map.get($my-typography, "headline-4"), "font-size") !important;
  font-weight: map.get(map.get($my-typography, "headline-4"), "font-weight") !important;
  line-height: map.get(map.get($my-typography, "headline-4"), "line-height") !important;
}

h5 {
  font-size: map.get(map.get($my-typography, "headline-5"), "font-size") !important;
  font-weight: map.get(map.get($my-typography, "headline-5"), "font-weight") !important;
}

h6 {
  font-size: map.get(map.get($my-typography, "headline-6"), "font-size") !important;
  font-weight: map.get(map.get($my-typography, "headline-6"), "font-weight") !important;
}

@include mat.all-component-themes($my-theme);
/**************************************************************************
=========================== Content Index =================================
* 1. General styles
* 4. Menu-2 styles
* 5. Products left sidebar page styles
* 7. Auth & Store account page styles
* 12. Product dialog styles
* 13. Popular products styles
**************************************************************************/
/***************************************/

/***************************************
* 1. General styles
****************************************/

html,
body {
  height: 100%;
}

body {
  margin: 0;
}
* {
  margin: 0;
  padding: 0;
}

.mat-mdc-raised-button:not([class*="mat-elevation-z"]) {
  box-shadow: none;
}

/*TODO(mdc-migration): The following rule targets internal classes of slider that may no longer apply for the MDC version.*/
.mat-slider-track-fill {
  background-color: $theme-main;
}

/*TODO(mdc-migration): The following rule targets internal classes of slider that may no longer apply for the MDC version.*/
.mat-primary .mat-slider-thumb {
  background-color: $theme-main;
}

.mat-mdc-raised-button.mat-warn {
  background-color: $theme-main-red;
}

.mat-mdc-card:not([class*="mat-elevation-z"]) {
  box-shadow: 2px 2px 6px 0px rgba(0, 0, 0, 0.1), 0 1px 1px 0 rgba(0, 0, 0, 0), 0 1px 3px 0 rgba(0, 0, 0, 0.12) !important;
}

// .overlay {
//   background-color: rgba(255, 193, 17, 0.8) !important;
//   position: fixed;
// }
/*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.mat-mdc-form-field.mat-focused .mat-form-field-label {
  color: $theme-main;
}

/*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.mat-mdc-form-field.mat-focused .mat-form-field-ripple {
  background-color: $theme-main;
}

.mat-expansion-indicator::after,
.mat-expansion-panel-header-description {
  color: $grey4;
}

.sophia-app .mat-drawer-container {
  background: #fff;
  position: relative;
  z-index: 1;
  box-sizing: border-box;
  -webkit-overflow-scrolling: touch;
  display: block;
  overflow: hidden;
}

.mat-drawer {
  background-color: #1a202c !important;
}

.mat-mdc-menu-panel {
  min-width: 100% !important;
  max-width: 100% !important;
}

.mat-mdc-mini-fab.mat-accent {
  background-color: $theme-main;
}

a {
  text-decoration: none;
}

.text-center {
  text-align: center !important;
}

li {
  list-style-type: none;
}

.mat-mdc-snack-bar-container.error {
  background: $theme-main-red !important;
}

.mat-drawer-backdrop {
  background-color: rgba(0, 0, 0, 0.6);
}

.mat-drawer-container {
  color: $font-color;
}

/*TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
.mat-radio-label-content {
  color: $grey7;
  font-weight: 400;
}

/*TODO(mdc-migration): The following rule targets internal classes of chips that may no longer apply for the MDC version.*/
.mat-mdc-chip.mat-mdc-standard-chip.mat-chip-selected.mat-warn {
  background-color: $theme-main-red;
}

/*TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
.mat-mdc-radio-button.mat-accent .mat-radio-inner-circle {
  background: $theme-main-red !important;
}

/*TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
.mat-mdc-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: $theme-main-red !important;
}

.mat-mdc-raised-button.mat-primary {
  background-color: $theme-main;
  color: $font-color;
}

.mat-mdc-input-element {
  caret-color: $theme-main !important;
}

.container {
  max-width: 1300px;
  margin: 0 auto;
  padding: 0 15px;
  min-height: 37vh;
}

.product-details-container {
  max-width: 80vw;
  margin: 0 auto;
  padding: 0 15px;
  min-height: 37vh;
}

.divider {
  border-top: 1px solid $naybrs-blue;
}

.bg {
  background: $background;
}

.ml-10 {
  margin-left: 10px;
}

.mb-5 {
  margin-bottom: 5px !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.mb-15 {
  margin-bottom: 15px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.mb-30 {
  margin-bottom: 30px !important;
}

.w-50 {
  width: 50%;
}

.mr-10 {
  margin-right: 10px !important;
}

.ml-10 {
  margin-left: 10px !important;
}

.ml-30 {
  margin-left: 30px !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.mt-15 {
  margin-top: 15px !important;
}

.mt-40 {
  margin-top: 40px !important;
}

.mt-15 {
  margin-top: 15px;
}

.mt-10 {
  margin-top: 10px !important;
}

.mb-15 {
  margin-bottom: 15px !important;
}

.mr-7 {
  margin-right: 7px !important;
}

.mr-30 {
  margin-right: 30px !important;
}

.ml-7 {
  margin-left: 7px !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pl-15 {
  padding-left: 15px;
}

.pr-15 {
  padding-right: 15px;
}

.no-pdn {
  padding: 0 !important;
}

.pt-20 {
  padding-top: 20px !important;
}

.pl-5 {
  padding-left: 5px !important;
}

.pr-5 {
  padding-right: 5px !important;
}

.pr-50 {
  padding-right: 50px !important;
}

.pt-40 {
  padding-top: 40px !important;
}

.pt-60 {
  padding-top: 60px !important;
}

.devider {
  padding-top: 15px;
  padding-bottom: 20px;
  border-top: 1px dashed $grey;
}

.w-100 {
  width: 100% !important;
}

.text-muted {
  color: $grey !important;
}

.sec-padding {
  padding: 60px 0;
}

.sec-padding-s {
  padding: 10px 0;
}

.sec-padding-b {
  padding-bottom: 60px;
}

.btn-project {
  background: $theme-main !important;
  padding: 5px 32px !important;
  color: $theme-main-dark !important;
}

.swiper-lazy-preloader {
  top: 18%;
}

.h-100 {
  height: 100%;
}

span {
  font-size: 14px;
}

.sidenav {
  width: 250px;
  padding: 8px 16px;
  position: fixed !important;
  z-index: 20;
}

.mat-toolbar-multiple-rows {
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  width: 100%;
}

/***************************************
*  Header-1 styles
****************************************/

/*  header styles */
.home-header {
  background: $naybrs-darkgrey;

  .top-navbar {
    background: transparent;
    /*TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
    .mat-button-wrapper {
      // display: flex;
      // flex-direction: row;
      // align-items: center;

      .top-cart {
        span {
          font-size: 15px;
          color: $white !important;
        }
      }
    }
  }

  .logo {
    padding: 11px 0;
  }

  .search-form {
    width: 100%;
    margin: 0 auto;
    /* margin-top: 6px; */
    padding: 13px 0;

    .form-wrap {
      background-color: transparent;
      white-space: nowrap;
      zoom: 1;
      width: 100%;
      margin: 0;
      position: relative;
      background: $grey4;
      background: rgba(0, 0, 0, 0.2);

      input {
        border-style: none;
        border-width: 1px;
        border-radius: 3px;
        border-color: $white;
        -webkit-transition: box-shadow 0.3s ease-out;
        transition: box-shadow 0.3s ease-out;
        height: 40px;
        width: 100%;
        max-width: 100%;
        padding: 0 0 0 12px;
        float: left;
        outline: none;
        box-shadow: none;
      }
    }

    .btn-search {
      overflow: visible;
      position: absolute;
      border: 0;
      margin: 0;
      cursor: pointer;
      height: 100%;
      min-width: 50px;
      right: -15px;
      top: 0;
      padding: 0 35px;
      color: $white;
      -webkit-transition: all 250ms ease-in-out;
      -moz-transition: all 250ms ease-in-out;
      -ms-transition: all 250ms ease-in-out;
      -o-transition: all 250ms ease-in-out;
      transition: all 250ms ease-in-out;
      text-transform: uppercase;
      background-color: $theme-main;
      border-radius: 0 3px 3px 0;
      -webkit-border-radius: 0 3px 3px 0;
      text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.3);
      -webkit-box-shadow: none;
      box-shadow: none;
    }
  }
}

/*  shopping widgets styles */
.commerce-buttons {
  .flex-row-button {
    /*TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
    .mat-button-wrapper {
      .cart-count-wrapper {
        position: absolute;
        top: -10px;
        left: 30px;
        background: $white;
        height: 18px;
        width: 18px;
        line-height: 18px;
        border-radius: 50%;
        font-size: 11px;
      }
    }
  }
}
// account menu
.spCard-main {
  width: 100%;

  .mat-mdc-menu-content {
    width: 320px !important;
  }

  .spCard-dropdown {
    background-color: $white;
    padding: 15px 10px;
    max-width: 320px;
    z-index: 20;

    .card-list-title {
      padding: 0 0 10px 0;
    }

    h4 {
      padding-bottom: 10px;
    }

    .new-product {
      .product {
        img {
          width: 70px;
        }
      }

      .close-circle {
        flex: 1;

        a {
          cursor: pointer;
          color: $grey3;
          transition: 0.3s;
          float: right;

          &:hover {
            color: $theme-main;
          }

          i {
            font-size: 20px;
          }
        }
      }

      .item {
        padding: 10px 0;

        .product {
          margin-right: 7px;
          background: $baners-background;
          border-radius: 3px;
        }

        .desc {
          p {
            font-weight: 500;
          }

          span {
            color: $grey;
            font-size: 15px;
            margin-top: 5px;
            /* padding-top: 7px; */
            display: block;
          }
        }
      }

      .total {
        padding-top: 15px;

        p {
          font-size: 15px;
        }

        span {
          font-weight: 500;
          font-size: 16px;
        }
      }
    }

    .card-list-title {
      a {
        color: $grey4;
        font-size: 14px;
        font-weight: 500;
      }

      p {
        color: $grey4;
        font-size: 14px;
        font-weight: 500;
      }
    }

    .woo-message {
      text-align: center;
      padding: 0;
      margin: 1.5em 0 0 0;
    }
  }
}

/***************************************
*  Home product section styles
****************************************/

.products {
  position: relative;

  .swiper-button-prev.swiper-button-disabled[_ngcontent-c25] {
    pointer-events: auto;
  }

  .product-wraper {
    padding: 20px 0px 20px 20px;

    .product-box {
      width: 100%;
    }
  }

  .back-img1 {
    position: absolute;
    left: 0;
    top: 56px;
  }

  .back-img2 {
    position: absolute;
    left: 0;
    bottom: 56px;
  }

  .back-img3 {
    position: absolute;
    right: 0;
    bottom: 235px;
  }

  .product-item {
    position: unset;
    z-index: -1;
    height: auto;
    transition: 0.4s;
    padding: 5px 5px 5px 5px;

    .mat-mdc-card:not([class*="mat-elevation-z"]) {
      box-shadow: 2px 2px 6px 0px rgba(0, 0, 0, 0.1), 0 1px 1px 0 rgba(0, 0, 0, 0), 0 1px 3px 0 rgba(0, 0, 0, 0.12) !important;
      padding: 0;

      .product-img {
        background: $baners-background;

        img {
          // max-width: 180px;
          margin: 12px auto 12px auto;
          display: block;
        }
      }

      .product-info-wrapper {
        padding: 15px;

        .icons {
          position: absolute;
          top: 30px;
          right: 10px;
          opacity: 0;
          transition: all 0.5s ease;

          a {
            color: $grey10;
            cursor: pointer;
            transition: 0.5s ease;
            margin-bottom: 5px;

            &:hover {
              color: $theme-main;
            }
          }
        }

        .stars {
          color: $theme-main;
          margin: 2px 0 10px 0;

          span {
            color: $grey3;
            font-size: 13px;
            margin-left: 10px;
          }

          mat-icon {
            font-size: 17px;
            line-height: 17px;
            height: 17px;
            width: 17px;
            vertical-align: middle;
          }
        }

        .category {
          margin-bottom: 4px;
          display: block;
          font-weight: 300;
        }

        span {
          color: $grey5;
          font-size: 14px;
        }

        .price-wrap {
          p {
            font-size: 19px;
          }

          mat-icon {
            color: $white;
          }

          a {
            // front page product icon
            background: $theme-main;
            cursor: pointer;
            transition: 0.3s;
            width: 38px;
            height: 38px;
            // background: $white-dark;
            background: $theme-main;
            border-radius: 50%;
            text-align: center;
            line-height: 46px;

            &:hover {
              // background: $theme-main;
              // background: $white-dark;

              filter: brightness(85%);
              // background: $naybrs-darkgrey;
            }

            mat-icon {
              transition: 0.3s;
              font-size: 18px;
            }
          }
        }

        .title-wrap {
          h4 {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            font-weight: 500;
          }
        }
      }

      &:hover {
        .icons {
          opacity: 1;
        }

        .price-wrap {
          a {
            background: $theme-main;
          }
        }
      }
    }

    button.swipe-arrow {
      position: absolute;
      top: 50%;
      margin-top: -22px;
      z-index: 99999999999;
      background-image: none;
      color: red;
    }
  }
}

.mat-mdc-tab-group.mat-primary .mat-ink-bar,
.mat-mdc-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: $theme-main !important;
}

/***************************************
*  Footer section styles
****************************************/
.footer {
  background-color: $naybrs-darkgrey;

  .footer-widget {
    padding-right: 15px;

    .social {
      margin-top: 20px;

      li {
        width: 33px;
        height: 33px;
        border: 1px solid $naybrs-blue;
        border-radius: 50%;
        text-align: center;
        margin-right: 5px;
        color: $naybrs-grey1;

        a {
          color: $naybrs-blue;

          i {
            color: $naybrs-blue;
          }
        }
      }
    }

    .ped-btn-footer {
      border: 1px solid $theme-main;
      color: $white !important;
      cursor: pointer;
      display: inline-block;
      font-size: 14px;
      font-weight: 700;
      margin-top: 20px;
      padding: 0px 10px;
      text-align: center;
      text-transform: uppercase;
      transition: all 0.4s ease 0s;
      z-index: 200;
      background: $theme-main;
      width: 100%;
      border-radius: 3px;
      line-height: 45px;
    }

    .title {
      margin-bottom: 10px;

      h5 {
        font-size: 18px;
        font-weight: 600;
        color: $naybrs-grey1;
      }
    }

    a {
      text-decoration: none;
      font-size: 15px;
      line-height: 32px;
      color: $naybrs-grey1;
    }

    .newsletter-widget {
      position: relative;
      margin: 15px 0 20px 0;
    }

    p {
      line-height: 24px;
      color: $naybrs-grey1;
    }

    ul {
      list-style-type: none;
    }

    .about-widget {
      .logo {
        img {
          max-width: 240px;
        }
      }
    }

    .tel-box {
      .icon {
        i {
          color: $naybrs-grey1;
          display: block;
          line-height: 27px;
          margin-right: 5px;
          width: 18px;
          text-align: center;
          font-size: 18px;
        }
      }

      .text {
        p {
          a {
            line-height: 22px;
          }
        }
      }
    }
  }

  .subscribe-widget {
    input {
      height: 45px;
      background: $white;
      font-size: 15px;
      line-height: 45px;
      border: 1px solid $white-dark;
      border-radius: 4px;
      padding: 0 65px 0 20px;
      color: $grey;
    }
  }
}

/***************************************
*  Home-2 product section styles
****************************************/
.home-2-wrap {
  .products-section {
    background: $grey6;

    .products {
      padding: 0 !important;

      .product-wraper {
        .product-box {
          .product-item {
            width: 240px;

            .product-img {
              background: $white;
            }
          }
        }
      }
      /*TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
      .mat-tab-labels {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
      }
    }

    h3 {
      margin-bottom: 0;
      display: inline-block;
      font-size: 23px;
      font-weight: 580;
      position: absolute;
    }
  }
}

.menu-container1 {
  background: $naybrs-darkgrey;

  a.mat-mdc-button {
    text-transform: capitalize;
    color: $white;
    font-size: 1em;
    font-weight: 500;
    padding: 0 25px;
    line-height: 63px;
  }
}

/***************************************
*  Menu-2 styles
****************************************/

.mat-mdc-menu-content {
  width: 221px;
  padding-top: 0px;
  padding-bottom: 0px;
  // padding: 0 13px !important;
  border-top: 4px solid $theme-main !important;

  .mat-mdc-menu-item {
    border-bottom: 1px dashed $gray-lighter;
  }
}

.top-navbar a {
  padding: 15px 0px;
}

.menu-container2 {
  background: $theme-main;

  a.mat-mdc-button {
    text-transform: uppercase;
    color: $white;
    font-size: 1em;
    font-weight: 700;
    padding: 0 25px;
    line-height: 63px;
  }
}

/***************************************
*  Sidebar styles
****************************************/

.mat-drawer {
  width: 240px;
  padding: 8px 14px;
  position: fixed;
  margin-left: -1px;
  background-color: $naybrs-darkgrey;
  color: #fff;
}

/***************************************
*  Products left sidebar page styles
****************************************/

.left-sidebar-wrapper
  mat-sidenav.filter-sidenav.mat-drawer.mat-sidenav.ng-tns-c15-4.ng-trigger.ng-trigger-transform.ng-tns-c13-3.ng-star-inserted.mat-drawer-over {
  padding-right: 10px;
}

.left-sidebar-wrapper {
  .mat-expansion-panel {
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    border: 1px solid $grey-light;
    box-shadow: none;
    margin-bottom: 20px;

    .mat-expansion-panel-header-title span {
      font-size: 17px;
      text-transform: capitalize;
      font-weight: 500;

      line-height: 1;
    }
  }

  .filter-sidenav {
    width: 280px;
    border: 0 !important;

    .brands {
      .title-header {
        margin-bottom: 15px;
        position: relative;
        padding-bottom: 1.25rem;

        h4 {
          &::before {
            content: "";
            left: 0;
            position: absolute;
            width: 100%;
            height: 0.0625rem;
            bottom: 0.34375rem;
            background-color: $grey6;
          }

          &::after {
            content: "";
            left: 0;
            position: absolute;
            width: 2.5rem;
            height: 0.125rem;
            background-color: $font-color;
            bottom: 0.3125rem;
          }
        }
      }

      .brand-logos {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-row-gap: 1em;
        grid-column-gap: 1em;

        .brand-item {
          display: inline-block;
          text-align: center;
          vertical-align: top;
          overflow: hidden;
          position: relative;
          margin-right: 10px;

          a {
            img {
              width: 100%;
            }
          }
        }
      }
    }
  }

  .filter-row {
    padding: 8px 25px;
    background: $gray-lighter;
    margin-bottom: 20px;
    box-shadow: 2px 2px 6px 0px rgba(0, 0, 0, 0.1), 0 1px 1px 0 rgba(0, 0, 0, 0), 0 1px 3px 0 rgba(0, 0, 0, 0.12) !important;
  }

  .all-products {
    padding-left: 17px;
    background: transparent;

    // background: $white;
    select {
      /* margin: 50px; */
      width: 150px;
      padding: 5px 35px 5px 5px;
      font-size: 16px;
      border: none;
      height: 34px;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      background: transparent;

      cursor: pointer;
      // background: url(/assets/images/dropdown.png) no-repeat 90%;
    }

    .col {
      padding: 9px 0 9px 0px;

      .product-item {
        position: relative;
        height: auto;
        padding: 0 10px;
        transition: 0.4s;

        .mat-mdc-card:not([class*="mat-elevation-z"]) {
          box-shadow: 2px 2px 6px 0px rgba(0, 0, 0, 0.1), 0 1px 1px 0 rgba(0, 0, 0, 0), 0 1px 3px 0 rgba(0, 0, 0, 0.12) !important;
          padding: 0;

          .product-img {
            background: $white;

            img {
              // max-width: 180px;
              margin: 12px auto 12px auto;
              display: block;
            }
          }

          .product-info-wrapper {
            padding: 15px;

            .icons {
              position: absolute;
              top: 30px;
              right: 10px;
              opacity: 0;
              transition: all 0.5s ease;

              a {
                color: $grey10;
                cursor: pointer;
                transition: 0.5s ease;
                margin-bottom: 5px;

                &:hover {
                  color: $theme-main;
                }
              }
            }

            .stars {
              color: $theme-main;
              margin: 2px 0 10px 0;

              span {
                color: $grey3;
                font-size: 13px;
                margin-left: 10px;
              }

              mat-icon {
                font-size: 17px;
                line-height: 17px;
                height: 17px;
                width: 17px;
                vertical-align: middle;
              }
            }

            .category {
              margin-bottom: 4px;
              display: block;
              font-weight: 300;
            }

            span {
              color: $grey5;
              font-size: 14px;
            }

            .price-wrap {
              p {
                font-size: 19px;
              }

              mat-icon {
                color: $white;
              }

              a {
                background: $theme-main;
                cursor: pointer;
                transition: 0.3s;
                width: 38px;
                height: 38px;
                background: $white-dark;
                border-radius: 50%;
                text-align: center;
                line-height: 46px;

                &:hover {
                  background: $theme-main;
                }

                mat-icon {
                  transition: 0.3s;
                  font-size: 18px;
                }
              }
            }

            .title-wrap {
              h4 {
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                font-weight: 500;
              }
            }
          }

          &:hover {
            .icons {
              opacity: 1;
            }

            .price-wrap {
              a {
                background: $theme-main;
              }
            }
          }
        }
      }
    }
  }

  .no-shadow {
    box-shadow: none !important;
  }
}

/***************************************
*  Menu-5 styles
****************************************/

.mat-mdc-menu-content {
  width: auto;
  padding-top: 0px;
  padding-bottom: 0px;
  // padding: 0 13px !important;
  border-top: 4px solid $theme-main !important;

  .mat-mdc-menu-item {
    border-bottom: 1px dashed $gray-lighter;
  }
}

.top-navbar a {
  padding: 15px 0px;
}

/***************************************
*  My account page styles
****************************************/
.my-account {
  .my-account-wrap {
    .title {
      margin-bottom: 15px;
      position: relative;
      padding-bottom: 1.25rem;

      &:before {
        content: "";
        left: 0;
        position: absolute;
        width: 100%;
        height: 0.0625rem;
        bottom: 0.34375rem;
        background-color: $grey6;
      }

      &:after {
        content: "";
        left: 0;
        position: absolute;
        width: 2.5rem;
        height: 0.125rem;
        background-color: $font-color;
        bottom: 0.3125rem;
      }
    }

    form {
      .mat-mdc-form-field {
        width: 100%;
        margin-bottom: 10px;
      }
    }

    .register-wrapper {
      .register-body {
        .mat-mdc-form-field {
          width: 100%;
          margin-bottom: 10px;
          margin-top: 10px;
        }

        p {
          font-size: 15px;
          font-weight: 400;
          line-height: 26px;
        }

        .button-wrap {
          margin-top: 20px;
        }
      }
    }
  }
}

/***************************************
*  Product dialog styles
****************************************/

.product-dialog {
  .mat-mdc-dialog-container {
    overflow: visible;
  }

  .swiper-container {
    .swiper-wrapper {
      img.img-fluid {
        margin: auto;
        max-width: 100%;
      }
    }
  }

  .product-right {
    padding: 0 30px;

    .product-details {
      .discription {
        padding-top: 15px;
        padding-bottom: 20px;
        border-top: 1px dashed $grey-light;
        border-bottom: 1px dashed $grey-light;

        .bold {
          font-weight: 600;
        }

        p.productDescription {
          line-height: 24px;
          margin-top: 5px;
        }
      }

      h4 {
        span {
          padding-left: 5px;
          color: $theme-main-red;
          font-size: 25px;
        }

        del {
          color: $grey;
          font-size: 18px;
        }
      }

      h3.price {
        font-size: 26px;
        font-weight: 400;
        margin: 10px 0;
      }
    }

    .quantity {
      input {
        width: 80px;
        text-align: center;
        background-color: $gray-lighter;
        height: 35px;
        border: 1px solid $grey3;
      }

      span {
        margin-bottom: 5px;
      }
    }

    .avalibility {
      .red {
        color: $theme-main-red;
        font-weight: 500;
      }
    }
  }

  .mat-mdc-dialog-container {
    .close-btn-outer button {
      position: absolute;
      right: -44px;
      top: -44px;
    }
  }

  .product-dialog {
    .close-btn-outer {
      position: relative;
    }
  }
}

.product-dialog .mat-mdc-dialog-content {
  max-width: 52vw;
}

/***************************************
*  Error page styles
****************************************/
.error {
  text-align: center;

  .page404 {
    font-size: 67px;
    font-weight: 700;
    color: $grey;
    margin-bottom: 30px;
  }

  .not-found {
    font-size: 39px;
    font-weight: 700;
    color: $font-color;
    margin-bottom: 30px;
  }

  .error_description {
    margin-bottom: 30px;

    p {
      font-weight: 600;
    }
  }

  button {
    background: $theme-main;
    color: $font-color;
    padding: 5px 33px;
    width: 200px;
  }
}

/***************************************
*  Checkout page styles
****************************************/

.billing-details {
  padding-right: 15px;
}

.checkout {
  .billing-details {
    padding-right: 15px !important;
    .mat-mdc-form-field {
      width: 100%;
    }

    .add-info {
      margin-top: 15px;
    }
  }

  .mainOrder-wrapper {
    .main-order {
      border: 1px solid $grey8;
      padding: 30px;

      .order-box {
        .title-box {
          position: relative;
          padding-bottom: 25px;
          color: $grey4;
          font-weight: 500;
          font-size: 20px;
          border-bottom: 1px solid $grey6;
          margin-bottom: 20px;

          span.tl-amount {
            position: relative;
            width: 35%;
            float: right;
            line-height: 1.2em;
          }
        }

        ul.price-list {
          position: relative;
          border-bottom: 1px solid $grey6;
          margin-bottom: 30px;
          padding-bottom: 20px;

          li {
            line-height: 44px;

            span {
              position: relative;
              width: 35%;
              float: right;
              font-size: 20px;
            }
          }
        }
      }

      ul.total-amount {
        position: relative;
        // border-bottom: 1px solid $grey6;
        // margin-bottom: 30px;
        // padding-bottom: 20px;
        padding: 20px;

        .shipping {
          width: 35%;
          float: right;

          .shopping-option {
            line-height: 30px;
          }
        }

        li {
          position: relative;
          display: inline-block;
          font-size: 16px;
          font-weight: 500;
          color: $font-color;
          line-height: 20px;
          margin-bottom: 20px;
          width: 100%;

          span.amount {
            position: relative;
            font-size: 20px;
            line-height: 20px;
            font-weight: 400;
            width: 35%;
            float: right;
            text-align: right; /* Add this line */
          }
        }
      }

      ul.quantity {
        position: relative;
        border-bottom: 1px solid $grey6;
        margin-bottom: 30px;
        padding-bottom: 20px;

        li {
          span.total {
            position: relative;
            font-size: 20px;
            line-height: 20px;
            font-weight: 400;
            width: 35%;
            float: right;
          }
        }
      }

      ul.total {
        position: relative;
        border-bottom: 1px solid $grey6;
        // margin-bottom: 30px;
        padding-bottom: 20px;

        li {
          position: relative;
          display: inline-block;
          font-size: 16px;
          font-weight: 500;
          color: $font-color;
          line-height: 20px;
          margin-bottom: 20px;
          width: 100%;

          span.count {
            position: relative;
            font-size: 20px;
            line-height: 20px;
            font-weight: 400;
            width: 35%;
            float: right;
          }
        }
      }

      .paymentFinal {
        margin-bottom: 20px;
        line-height: 34px;
      }
    }
  }
}

/*TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
.mat-radio-label-content {
  color: $grey5;
  font-weight: 400;
}

/***************************************
*  Product & Store details styles
****************************************/
.details-wrapper {
  padding: 30px 0;

  .product-image {
    div {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        max-width: 100%;
        max-height: 100%;
      }
    }
  }

  .product-right {
    padding: 0 30px 0 25px;

    .product-details {
      padding-bottom: 15px;
      border-bottom: 0px dashed $grey-light;

      h2 {
        margin-bottom: 10px;
      }

      h4 {
        del {
          color: $grey;
          font-size: 17px;
        }

        span {
          padding-left: 5px;
          color: $theme-main-red;
          font-size: 24px;
        }
      }

      .avalibility {
        span {
          color: $theme-main-red;
          font-weight: 500;
        }
      }

      p.description {
        line-height: 26px;
      }

      h3.price {
        font-size: 26px;
        font-weight: 400;
        margin: 10px 0;
      }
    }

    .quantity {
      margin-top: 10px;

      span {
        margin-bottom: 7px;
        display: block;
      }

      input {
        width: 80px;
        text-align: center;
        background-color: $white-dark;
        height: 35px;
        border: 1px solid $grey3;
      }
    }
  }

  .products {
    padding-right: 30px;

    .title {
      margin-bottom: 15px;
      position: relative;
      padding-bottom: 1.25rem;

      &::before {
        content: "";
        left: 0;
        position: absolute;
        width: 100%;
        height: 0.0625rem;
        bottom: 0.34375rem;
        background-color: #f6f6f6;
      }

      &::after {
        content: "";
        left: 0;
        position: absolute;
        width: 2.5rem;
        height: 0.125rem;
        background-color: #222222;
        bottom: 0.3125rem;
      }
    }
  }

  .sale-widget {
    padding: 15px 20px;
    background: $theme-main;
    text-align: center;
    margin-bottom: 25px;
    margin-top: 25px;

    .sale-widget1 {
      color: $white;
      font-size: 36px;
      font-weight: 600;
      display: block;
      letter-spacing: 0.5;
    }

    .sale-widget2 {
      color: $white;
      font-size: 18px;
      font-weight: 600;
      display: block;
      letter-spacing: 0.5;
    }

    .discount {
      display: inline-block;
      color: $grey-dark;
      font-size: 77px;
      font-weight: 600;
      position: relative;
      letter-spacing: 0.5;
    }

    .percent {
      display: inline-block;
      line-height: 31px;

      span {
        display: block;
        font-size: 3.067em;
        font-weight: 500;
      }
    }

    p {
      color: $white;
      line-height: 24px;
      font-weight: 300;
      font-size: 15px;
    }
  }

  .small-carousel {
    position: relative;
    margin-top: 10px;

    .swiper-container {
      height: 180px;

      .swiper-slide {
        /*TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version.*/
        mat-card {
          max-height: 120px;
          min-height: 120px;
          max-width: 120px;

          img {
            max-width: 110px;
            margin-bottom: 0px;
            display: block;
            margin-left: auto;
            margin-right: auto;
            cursor: pointer;
          }
        }
      }

      .swiper-button-prev {
        position: absolute;
        top: 42% !important;
        left: 0px;
        z-index: 21px !important;
      }

      .swiper-button-next {
        position: absolute;
        top: 42% !important;
        right: 0px;
        z-index: 21px !important;
      }
    }
  }

  .social-icons {
    padding-top: 15px;
    border-top: 1px dashed $grey-light;

    ul {
      li {
        width: 33px;
        height: 33px;
        border: 1px solid $grey3;
        border-radius: 50%;
        text-align: center;
        margin-right: 5px;

        a {
          text-decoration: none;
          font-size: 15px;
          line-height: 32px;
          color: $grey-light;

          i {
            color: $grey4;
          }
        }
      }
    }
  }
}

.product-slick.product-zoom.slick-initialized.slick-slider {
  z-index: 1;
}

.mat-mdc-list-base .mat-mdc-list-item.mat-3-line,
.mat-list-base .mat-list-option.mat-3-line {
  height: 100% !important;
  margin-bottom: 20px;
}

/***************************************
*  Categorie page styles
****************************************/

.subcategory-content {
  ul {
    li {
      a {
        color: $grey;
        padding-bottom: 10px;
        display: block;
        font-size: 15px;
      }
    }
  }
}

.sidenav {
  .divider {
    margin: 17px 0;
  }
}

:host {
  display: flex;
  flex-direction: column;
  outline: none;
  width: 100%;

  .mat-mdc-list-item.active {
    background-color: mat-color($theme-main, 50);
  }

  &:hover,
  &:focus {
    > .mat-mdc-list-item:not(.expanded) {
      background-color: mat-color($theme-main, 100) !important;
    }
  }
}

.mat-mdc-list-item {
  padding: 10px 0;
  display: flex;
  width: auto;
  /*TODO(mdc-migration): The following rule targets internal classes of list that may no longer apply for the MDC version.*/
  .mat-list-item-content {
    width: 100%;
  }
  .routeIcon {
    margin-right: 40px;
  }
}

.ww-border-b {
  border-bottom: $naybrs-blue; // Replace 'blue' with your desired color
  border-left: none;
  border-right: none;
  border-style: solid;
  border-width: 1px;
  border-top: $naybrs-blue;
}

.ww-border-t {
  border-top: $naybrs-blue;
  border-left: transparent;
  border-right: transparent;
  border-style: solid;
  border-width: 5px;
}

a.mat-mdc-button {
  color: $font-headline-dark;
}

.ww-blue-icon {
  color: $naybrs-blue !important;
}

/*TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
div.mat-button-wrapper > div:nth-child(1) > mat-icon:nth-child(1) {
  color: $naybrs-blue;
}

.info-text > span:nth-child(2) {
  color: $white;
  font-size: 14px !important;
}

.text-white {
  color: $white !important;
}

.text-black {
  color: $black !important;
}

.header-item {
  color: #fff;
  text-decoration: none;
  font-size: 13px;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.header-item:hover {
  color: #1473e6;
}

.extended-fab-button {
  width: unset;
  padding: 0 26px;
  border-radius: 29px;
}

.main-menu > button > span > mat-icon {
  margin-right: 10px;
}

.prod-menu {
  margin-top: 20px;
  margin-bottom: 20px;

  ul {
    li {
      cursor: pointer;
      transition: 0.3s;
      max-width: 80px;

      .icon {
        height: 50px;

        img,
        svg {
          max-height: 45px;
          fill: $grey4 !important;

          .cls-1 {
            stroke: $grey4 !important;
          }
        }
      }

      .text {
        p {
          color: $grey;
          margin: 0;
        }
      }

      &:hover {
        transition: 0.3s;

        .icon {
          svg {
            color: $theme-main;
          }
        }

        .text {
          p {
            transition: 0.3s;
            color: $theme-main;
          }
        }
      }
    }
  }
}

/*TODO(mdc-migration): The following rule targets internal classes of list that may no longer apply for the MDC version.*/
.mat-list-item-content {
  color: white !important;
}

.horizontal-btn ~ .horizontal-btn {
  margin-left: 16px;
}

.cart-dropdown .spCard-main {
  width: 300px;
}

.btn-text-white {
  color: white !important;
}

.home-cont {
  max-width: 1480px !important;
  min-height: 80vh;
  margin-bottom: 20px;
}

.home-sec-padding {
  padding-top: 40px;
  padding-bottom: 40px;
}

.example-container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #eee;
}

.my-account-text-info {
  color: $theme-main;
  font-size: 0.85rem;
}

.Sign.Up-wrapper {
  max-width: 100% !important;
}

.center {
  margin: 0 auto !important;
  width: 340px !important;
}

// so that maps element

.anc {
  /* The ancestors of the map element */
  height: 100%;
}

#map {
  height: 500px;
  margin-right: 20px;
}

.dark-background {
  color: $naybrs-darkgrey !important;
}

.location-bar {
  border-top: 0px;
}

.location-names {
  color: black !important;
}

.review-text {
  color: black !important;
}

.product-review-star mat-icon {
  color: $naybrs-blue;
}

/*TODO(mdc-migration): The following rule targets internal classes of list that may no longer apply for the MDC version.*/
.mat-mdc-list-item.mat-list-item-word-wrap {
  height: initial !important;

  .mat-line {
    word-wrap: break-word !important;
    white-space: pre-wrap !important;
  }
}

.order-list-item {
  color: #1a202c !important;
}

.sec-padding-xs {
  padding: 30px 0;
}

.slider-square {
  height: 100vw !important;
}

.pl0 {
  padding-left: 0px !important;
}

.swiper-container {
  width: 100%;
  height: 300px;
  margin: 0px auto;
}

.swiper-slide-small {
  // background: #f1f1f1;
  color: #000;
  text-align: center;
  line-height: 300px;
  height: 100vw !important;
}

.swiper-slide {
  // background: #f1f1f1;
  color: #000;
  text-align: center;
  // line-height: 300px;
}

.slide-img {
  height: 100vw;
}

// making cards on front page white
/*TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version.*/
mat-card.mat-mdc-card.mat-focus-indicator.swiper-slide.ng-star-inserted {
  background-color: white;
}

/*TODO(mdc-migration): The following rule targets internal classes of dialog that may no longer apply for the MDC version.*/
#mat-dialog-0 > profile-modal > div.ng-star-inserted > div {
  min-height: 90px !important;
}

mat-icon.headline-icon {
  vertical-align: middle;
  position: relative;
  font-size: 24px;
  padding-right: 0.25em;
}

.icon-spacing {
  margin-right: 5px !important;
}
.icon-padding {
  padding-right: 5px !important;
}

/*TODO(mdc-migration): The following rule targets internal classes of chips that may no longer apply for the MDC version.*/
.mat-mdc-chip.mat-mdc-standard-chip.mat-chip-selected.mat-warn {
  background-color: $naybrs-blue;
}

/*TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
.mat-mdc-radio-button.mat-accent .mat-radio-inner-circle {
  background: $naybrs-blue !important;
}

/*TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
.mat-mdc-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: $naybrs-blue !important;
}

/*TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version.*/
.mat-card-header-text {
  margin: 0 0px !important;
}

.store-info-bottom {
  padding: 0px 0px 0px 0px !important;
}

.store-selected {
  color: greenyellow;
}

.mat-toolbar {
  background: #1a202c !important;
}

.mat-expansion-panel-body {
  padding: 16px 24px 16px !important;
}

mat-panel-title {
  padding: 10px 0px 10px 0px !important;
}

.vertical-list {
  display: flex !important;
  flex-direction: column !important;
}

/*TODO(mdc-migration): The following rule targets internal classes of list that may no longer apply for the MDC version.*/
mat-list-option.white-text {
  color: white !important;
}

/* Adam changes*/
.mat-mdc-dialog-container,
.mat-mdc-dialog-surface,
.mat-mdc-card,
.mat-mdc-expansion-panel,
.left-sidebar-wrapper .filter-row,
.product-img,
.details-wrapper .info-bar,
.details-wrapper .sale-widget,
.home-header .search-form .form-wrap input,
.mat-mdc-button,
.mat-mdc-menu-panel,
.mat-expansion-panel,
button,
.mat-mdc-dialog-container,
.submit-order,
.mat-mdc-dialog-container,
.btn-project {
  border-radius: 28px !important;
  // border-radius: 28px !important;
}

.home-header .search-form .btn-search {
  border-radius: 0px 8px 8px 0px !important;
}

.details-wrapper .small-carousel .swiper-container .swiper-slide mat-card img {
  margin-bottom: 0px !important;
}

.flex-center {
  display: flex;
  align-items: center;
}

.flex-center span {
  display: flex;
  align-items: center;
}

.transparent-bg {
  background-color: transparent !important;
}

.ww-darkgrey-bg {
  background-color: $naybrs-darkgrey !important;
}

.small-icon-button {
  width: 30px !important;
  height: 30px !important;
  padding: 0px !important;
  display: inline-flex !important;
  align-items: center;
  justify-content: center;

  & > *[role="img"] {
    width: 16px;
    height: 16px;
    font-size: 16px;

    svg {
      width: 16px;
      height: 16px;
    }
  }

  .mat-mdc-button-touch-target {
    width: 24px !important;
    height: 24px !important;
  }
}

.example-spacer {
  flex: 1 1 auto;
}

.red-icon {
  color: #d03275 !important;
}

.green-icon {
  color: green !important;
}
.ngx-file-drop__drop-zone {
  height: 100% !important;
}

ngx-file-drop {
  height: 250px !important; // asdasda
  display: block !important;
}

.ngx-file-drop__content {
  height: 250px !important;
}

.file-zone-drop {
  padding: 0px !important;
}

input {
  line-height: 1rem !important;
}

.snackbar {
  .mat-mdc-snackbar-surface {
    background-color: $naybrs-darkgrey !important;
    color: $naybrs-blue !important;
  }
}

// ridiculous selector for the spinner. fix at a luxury later.
.loading-spinner-icon
  mat-spinner
  div.mdc-circular-progress__indeterminate-container
  div
  div.mdc-circular-progress__circle-clipper.mdc-circular-progress__circle-left
  svg
  circle {
  stroke: white !important;
}

.fixed-width-button {
  width: 85px !important; /* Adjust the width as needed */
}

.button-content {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  width: 100% !important;
}

.loading-spinner-icon {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  width: 100% !important;
}

.bg-white {
  background-color: white !important;
}

.mat-mdc-menu-content {
  border-top-width: 0 !important;
}

.mat-mdc-menu-panel.apps-menu {
  width: 350px !important;
  // height: 150px !important;
}
.mat-mdc-menu-panel.user-menu {
  width: 400px !important;
  width: 415px !important;
}

.apps-menu {
  .mat-mdc-menu-content {
    padding: 15px !important;
  }
}

.bg-gradient {
  background: linear-gradient(to bottom, #1a202c, #394152) !important
    ;
}
