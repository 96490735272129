@media (min-width: 1200px) and (max-width: 1480px) {
  .product-dialog {
    .mat-mdc-dialog-content {
      max-width: 64vw;
      .product-right {
        .buttons {
          .btn-project {
            max-width: 125px;
            padding: 4px 18px !important;
            font-size: 10px !important;
          }
        }
      }
    }
  }
}

@media (max-width: 1199px) {
  .product-dialog {
    .mat-mdc-dialog-content {
      max-width: 64vw;
      .product-right {
        .buttons {
          .btn-project {
            max-width: 115px;
            padding: 4px 18px !important;
            font-size: 10px !important;
          }
        }
      }
    }
  }
}

@media (max-width: 959px) {
  .details-wrapper {
    .product-right {
      padding: 0;
      margin-top: 25px;
    }
  }
  .left-sidebar-wrapper {
    .all-products {
      padding-left: 0;
    }
  }
}
@media (min-width: 960px) and (max-width: 1199px) {
  .products {
    .product-item {
      .mat-mdc-card:not([class*="mat-elevation-z"]) {
        .product-img img {
          max-width: 131px;
        }
      }
    }
  }
}
@media (min-width: 769px) and (max-width: 991px) {
  .home-header {
    .logo {
      margin: 0 auto;
      margin-bottom: 0;
    }
  }
}
@media (min-width: 600px) and (max-width: 959px) {
  .billing-details {
    padding-right: 0 !important;
    margin-bottom: 20px;
  }
  .mainOrder-wrapper {
    padding-left: 0;
    padding-top: 15px;
  }

  .details-wrapper {
    .product-image {
      margin-bottom: 20px;
    }
    .product-right {
      padding: 0px;
    }
  }

  .left-sidebar-wrapper {
    .filter-sidenav {
      width: 100%;
      .toggles-wrapper {
        .toggles {
          padding-right: 7px;
        }
      }
    }
  }
}
@media (max-width: 768px) {
  .left-sidebar-wrapper {
    .all-products {
      padding-left: 0;
    }
  }
  .home-header {
    .logo {
      margin: 0 auto;
      margin-bottom: 0;
    }
  }

  .billing-details {
    padding-right: 0 !important;
    // margin-bottom: 20px;
  }
  .mainOrder-wrapper {
    padding-left: 0;
  }

  @media (max-width: 649px) {
    .home-header {
      .search-form {
        width: 95%;
        padding: 0;
      }
    }
  }

  @media (max-width: 599px) {
    .info-bar {
      .card-wrap {
        padding: 0 !important;
      }
    }

    .vertical-products {
      .product-panel {
        &:not(:last-child) {
          padding-right: 0px;
          margin-bottom: 30px;
        }
      }
    }
    .mt-2 {
      margin-top: 20px;
    }

    .left-sidebar-wrapper {
      .filter-sidenav {
        width: 100% !important;
      }
    }
    .left-sidebar-wrapper {
      .all-products {
        padding-left: 0px;
        .col {
          .product-item {
            margin-right: 0px;
          }
        }
      }
    }

    .mat-toolbar-row {
      height: 100% !important;
      padding: 8px 15px 8px 2px !important;
    }

    .cdk-global-overlay-wrapper {
      .product-dialog {
        max-width: 80vw !important;
        min-width: 90% !important;
      }
    }
    .product-dialog {
      .mat-mdc-dialog-content {
        max-width: 73vw !important;
      }
    }
    .product-dialog {
      .mat-mdc-dialog-content {
        max-width: 52vw;
        .product-right {
          padding: 0px !important;
          .buttons {
            .btn-project {
              padding: 3px 12px !important;
            }
          }
        }
      }
    }
  }
  @media (max-width: 480px) {
    .checkout {
      .mainOrder-wrapper {
        padding-top: 15px;
        .main-order {
          padding-left: 10px;
          padding-right: 10px;
          ul.total-amount {
            .shipping {
              /* width: 35%; */
              /* float: right; */
              width: unset;
              float: unset;
              display: flex;
              flex-direction: column;
              .shopping-option {
                .mat-mdc-radio-group {
                  display: flex;
                  flex-direction: column;
                }
              }
            }
          }
          ul.price-list {
            li {
              line-height: 29px !important;
              display: flex;
              flex-direction: column;
              margin-bottom: 10px;
            }
          }
        }
      }
    }

    .commerce-buttons {
      .mat-mdc-button {
        padding-right: 0px !important;
      }
    }

    .product-details-container {
      padding-left: 0px;
      padding-right: 0px;
      max-width: 90vw !important;
    }

    .sec-padding {
      padding: 20px 0 !important;
    }

    //mobile search bar - removes ugly space between search bar and location nav
    body
      > app-root
      > mat-sidenav-container
      > mat-sidenav-content
      > app-header
      > div:nth-child(1)
      > mat-toolbar
      > mat-toolbar-row:nth-child(2) {
      padding-top: 0px !important;
    }

    // makes productg details slider square images
    .m-w-100 {
      width: 100%;
    }

    //wrapper for div holding price on product details
    .details-wrapper {
      padding-top: unset;
    }

    // selector for div holding the price in product details
    body
      > app-root
      > mat-sidenav-container
      > mat-sidenav-content
      > app-product-details
      > div.container.product-details-container
      > div
      > div:nth-child(1)
      > div:nth-child(1)
      > div.mt-2 {
      margin-top: unset;
    }
  }
}
