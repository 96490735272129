//colors
$theme-main: #1473e6;
$naybrs-blue: #1473e6;
$naybrs-darkgrey: #1a202c;
$theme-main-red: #f33927;
$theme-main-dark: #383838;
$white: #ffffff;
$white-dark: #e2e2e2;
$black: #000000;
$dark-font: #222222;
$grey-dark: #2d2a25;
$font-color: #333333;
$font-headline-dark: $naybrs-blue;
$grey-light: #dddddd;
$grey: #777777;
$grey2: #eeeeee;
$grey3: #c3c3c3;
$grey4: #444;
$grey5: #848484;
$grey6: #e5e5e5;
$grey7: #787878;
$grey8: #d9d9d9;
$grey9: #f3f3f3;
$grey10: #9c9c9c;
$baners-background: #fff;
$background: #fafafa;
$gray-lighter: #f6f6f6;
$footer-background: #f7f7f7;
$naybrs-grey1: #c4d1e9;
$naybrs-grey2: #55678c;
$naybrs-grey3: #47597b;
$naybrs-grey4: #374765;
$naybrs-grey5: #152238;
$naybrs-grey6: #1a202c;
$naybrs-blue1: #5bb4ff;
$naybrs-blue2: #1473e6;
$naybrs-blue3: #1861d4;
$naybrs-blue4: #1b41b4;

/* For use in src/lib/core/theming/_palette.scss */
$naybrs-palette: (
  50: #e3eefc,
  100: #b9d5f8,
  200: #8ab9f3,
  300: #5b9dee,
  400: #3788ea,
  500: #1473e6,
  600: #126be3,
  700: #0e60df,
  800: #0b56db,
  900: #0643d5,
  A100: #fdfeff,
  A200: #cad7ff,
  A400: #97b0ff,
  A700: #7e9dff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);
