/* You can add global styles to this file, and also import other style files */
@use "assets/tailwind.scss";

// // commented out to fix error after upgrading to material 17
// @use "@angular/material" as mat;

// @import "material-icons/iconfont/material-icons.scss";

// fix for error above
$material-icons-font-path: "material-icons/iconfont/";
@import "material-icons";

@import "../../../node_modules/swiper/swiper-bundle.css";

// Color 1 scss
@import "./assets/scss/color1.scss";

// // commented out to fix error after upgrading to material 17
// @import "@angular/material/theming";

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, system-ui, BlinkMacSystemFont;
}

// __<<ngM2ThemingMigrationEscapedComment1>>__
.gmnoprint a,
.gmnoprint span,
.gm-style-cc {
  display: none !important;
}

// .gmnoprint div {
//   background: none !important;
// }

// #GMapsID div div a div img {
//   display: none !important;
// }

.products
  .product-item
  .mat-mdc-card:not([class*="mat-elevation-z"])
  .product-info-wrapper
  .price-wrap
  a {
  border-radius: 12px !important;
}
/* TODO(mdc-migration): The following rule targets internal classes of list that may no longer apply for the MDC version. */
.mat-list-item-content {
  color: black !important;
}

// replacement for angular flex package
@import "./assets/scss/theme/angular-flex-replacement.scss";

// workaround for border bug in input fields when using anguylar material and tailwind:
.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch {
  border-right-style: hidden;
}
// workaround for border bug in input fields when using anguylar material and tailwind:
.mat-mdc-input-element {
  box-shadow: none !important;
}