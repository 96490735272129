// Flex row/col + grid.
.flex-row {
  display: flex;
  flex-direction: row;
}
.flex-col {
  display: flex;
  flex-direction: column;
}
.grid {
  display: grid;
}

// Flex-wrap utils.
.flex-wrap {
  flex-wrap: wrap;
} // Mostly used with flex-row, when wrapping is desired.

// Flex direction utils.q
.flex-row-gt-xs {
  @media screen and (min-width: 600px) {
    flex-direction: row;
  }
}

.flex-row-gt-sm {
  @media screen and (min-width: 960px) {
    flex-direction: row;
  }
}

.flex-row-gt-md {
  @media screen and (min-width: 1280px) {
    flex-direction: row;
  }
}

.flex-row-gt-lg {
  @media screen and (min-width: 1920px) {
    flex-direction: row;
  }
}

// Less than breakpoints
.flex-row-lt-sm {
  @media screen and (max-width: 599px) {
    flex-direction: row;
  }
}

.flex-row-lt-md {
  @media screen and (max-width: 959px) {
    flex-direction: row;
  }
}

.flex-row-lt-lg {
  @media screen and (max-width: 1279px) {
    flex-direction: row;
  }
}

.flex-row-lt-xl {
  @media screen and (max-width: 1919px) {
    flex-direction: row;
  }
}

// Exactly xs breakpoint
.flex-row-xs {
  @media screen and (min-width: 0px) and (max-width: 599px) {
    flex-direction: row;
  }
}

.flex-col-xs {
  @media screen and (min-width: 0px) and (max-width: 599px) {
    flex-direction: column;
  }
}

// Exactly sm breakpoint
.flex-row-sm {
  @media screen and (min-width: 600px) and (max-width: 959px) {
    flex-direction: row;
  }
}

.flex-col-sm {
  @media screen and (min-width: 600px) and (max-width: 959px) {
    flex-direction: column;
  }
}

// Greater than breakpoints
.flex-col-gt-xs {
  @media screen and (min-width: 600px) {
    flex-direction: column;
  }
}

.flex-col-gt-sm {
  @media screen and (min-width: 960px) {
    flex-direction: column;
  }
}

.flex-col-gt-md {
  @media screen and (min-width: 1280px) {
    flex-direction: column;
  }
}

.flex-col-gt-lg {
  @media screen and (min-width: 1920px) {
    flex-direction: column;
  }
}

// Less than breakpoints
.flex-col-lt-sm {
  @media screen and (max-width: 599px) {
    flex-direction: column;
  }
}

.flex-col-lt-md {
  @media screen and (max-width: 959px) {
    flex-direction: column;
  }
}

.flex-col-lt-lg {
  @media screen and (max-width: 1279px) {
    flex-direction: column;
  }
}

@media screen and (min-width: 768px) and (max-width: 959px) {
  .flex-md-66 {
    flex: 1 1 66%;
  }
}

@media screen and (min-width: 960px) {
  .flex-gt-md-66 {
    flex: 1 1 66%;
  }
}

.flex-col-lt-xl {
  @media screen and (max-width: 1919px) {
    flex-direction: column;
  }
}

@media screen and (min-width: 960px) {
  // 960px is the usual breakpoint for 'md'
  .flex-gt-md-20 {
    flex: 1 1 20%;
  }
}

@media screen and (min-width: 600px) {
  // 600px is the usual breakpoint for 'sm'
  .flex-gt-sm-100 {
    flex: 1 1 100%;
  }
}

@media screen and (min-width: 960px) {
  // 960px is the usual breakpoint for 'md'
  .flex-gt-md-100 {
    flex: 1 1 100%;
  }
}

@media screen and (min-width: 960px) and (max-width: 1279px) {
  // 'md' breakpoint range
  .flex-md-55 {
    flex: 1 1 55%;
  }
}

.flex-75 {
  flex: 1 1 75%;
}

.flex-25 {
  flex: 1 1 25%;
}

.flex-30 {
  flex: 1 1 30%;
}

.flex-45 {
  flex: 1 1 45%;
}

@media screen and (min-width: 600px) {
  // 'sm' breakpoint
  .flex-gt-sm-100 {
    flex: 1 1 100%;
  }
  .flex-gt-sm-30 {
    flex: 1 1 30%;
  }
  .flex-gt-sm-22 {
    flex: 1 1 22%;
  }
  .flex-gt-sm-50 {
    flex: 1 1 50%;
  }
  .flex-offset-gt-sm-10 {
    margin-left: 10%;
  }
}

@media screen and (min-width: 960px) and (max-width: 1279px) {
  // 'md' breakpoint range
  .flex-md-45 {
    flex: 1 1 45%;
  }
  .flex-md-66 {
    flex: 1 1 66%;
  }
  .flex-md-75 {
    flex: 1 1 75%;
  }
}

@media screen and (min-width: 960px) {
  // 'md' breakpoint
  .flex-gt-md-66 {
    flex: 1 1 66%;
  }
}

@media screen and (max-width: 599px) {
  // less than 'sm' breakpoint
  .flex-lt-sm-100 {
    flex: 1 1 100%;
  }
}

@media screen and (max-width: 959px) {
  // less than 'md' breakpoint
  .flex-lt-md-100 {
    flex: 1 1 100%;
  }
}

@media screen and (min-width: 600px) and (max-width: 959px) {
  // 'sm' breakpoint range
  .flex-sm-50 {
    flex: 1 1 50%;
  }
}

// Gap.
.gap-4 {
  gap: 4px;
}
.gap-8 {
  gap: 8px;
}
.gap-10 {
  gap: 10px;
}
.gap-12 {
  gap: 12px;
}
.gap-16 {
  gap: 16px;
}
// Justify content
.justify-start {
  justify-content: flex-start;
}
.justify-center {
  justify-content: center;
}
.justify-end {
  justify-content: flex-end;
}
.justify-space-between {
  justify-content: space-between !important;
}
.justify-space-around {
  justify-content: space-around;
}
.justify-space-evenly {
  justify-content: space-evenly;
}

// Align items
.items-start {
  align-items: flex-start;
}
.items-center {
  align-items: center;
}
.items-end {
  align-items: flex-end;
}
.items-stretch {
  align-items: stretch;
}
// Justify content for exactly xs breakpoint
@media screen and (min-width: 0px) and (max-width: 599px) {
  .justify-start-xs {
    justify-content: flex-start;
  }
  .justify-center-xs {
    justify-content: center;
  }
  .justify-end-xs {
    justify-content: flex-end;
  }
  .justify-space-between-xs {
    justify-content: space-between;
  }
  .justify-space-around-xs {
    justify-content: space-around;
  }
}

// Justify content for greater than xs breakpoint
@media screen and (min-width: 600px) {
  .justify-start-gt-xs {
    justify-content: flex-start;
  }
  .justify-center-gt-xs {
    justify-content: center;
  }
  .justify-end-gt-xs {
    justify-content: flex-end;
  }
  .justify-space-between-gt-xs {
    justify-content: space-between;
  }
  .justify-space-around-gt-xs {
    justify-content: space-around;
  }
}

// Align items for exactly xs breakpoint
@media screen and (min-width: 0px) and (max-width: 599px) {
  .items-start-xs {
    align-items: flex-start;
  }
  .items-center-xs {
    align-items: center;
  }
  .items-end-xs {
    align-items: flex-end;
  }
  .items-stretch-xs {
    align-items: stretch;
  }
}

// Align items for greater than xs breakpoint
@media screen and (min-width: 600px) {
  .items-start-gt-xs {
    align-items: flex-start;
  }
  .items-center-gt-xs {
    align-items: center;
  }
  .items-end-gt-xs {
    align-items: flex-end;
  }
  .items-stretch-gt-xs {
    align-items: stretch;
  }
}

.flex-100 {
  flex: 1 1 100%;
}

@media screen and (min-width: 600px) {
  // 600px is the usual breakpoint for 'sm'
  .flex-gt-sm-100 {
    flex: 1 1 100%;
  }
}

@media screen and (min-width: 600px) {
  // 600px is the usual breakpoint for 'sm'
  .flex-gt-sm-80 {
    flex: 1 1 80%;
  }
}

// Justify content for exactly xs breakpoint
@media screen and (min-width: 0px) and (max-width: 599px) {
  .justify-space-evenly-xs {
    justify-content: space-evenly;
  }
}

// Justify content for greater than xs breakpoint
@media screen and (min-width: 600px) {
  .justify-space-evenly-gt-xs {
    justify-content: space-evenly;
  }
}

@media screen and (min-width: 960px) {
  // 960px is the usual breakpoint for 'md'
  .flex-gt-md-50 {
    flex: 1 1 50%;
    max-width: 50%;
  }
}

@media screen and (min-width: 960px) and (max-width: 1279px) {
  // 'md' breakpoint range
  .flex-md-50 {
    flex: 1 1 50%;
  }
}

// Flex/grow/shrink properties https://developer.mozilla.org/en-US/docs/Web/CSS/flex.
.flex-1 {
  flex: 1;
} // Same as flex: 1 1 0 (grow, shrink, basis 0). Has similar effect to width: 100%;
.flex-grow {
  flex-grow: 1;
} // Same as flex: 1 1 auto (grow, shrink, basis auto). For spacer, etc.

// Hide & show for different breakpoints.
.hide-xs {
  @media screen and (max-width: 599px) {
    display: none;
  }
} // Hide on mobile.
.hide-gt-xs {
  @media screen and (min-width: 600px) {
    display: none;
  }
} // Show only on mobile. Hide on desktop.
.hide-sm {
  @media screen and (max-width: 959px) {
    display: none;
  }
} // Hide on mobile/tablet.
.hide-md {
  @media screen and (max-width: 1279px) {
    // 1279px is the usual breakpoint for 'md'
    display: none;
  }
} // Hide on mobile/tablet/medium screens.
.hide-gt-sm {
  @media screen and (min-width: 960px) {
    display: none;
  }
} // Show only on mobile/tablet. Hide on desktop.

// Classes for ngClass replacement

@media screen and (max-width: 599px) {
  // 'xs' breakpoint
  .mt-xs-10 {
    margin-top: 10px; // adjust units as needed
  }
}

@media screen and (min-width: 600px) and (max-width: 959px) {
  // 'sm' breakpoint range
  .mt-sm-10 {
    margin-top: 10px; // adjust units as needed
  }
}

@media screen and (max-width: 599px) {
  // 'xs' breakpoint
  .mt-xs-20 {
    margin-top: 20px;
  }
}

@media screen and (min-width: 600px) and (max-width: 959px) {
  // 'sm' breakpoint range
  .mt-sm-20 {
    margin-top: 20px;
  }
}
